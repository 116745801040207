<template>
  <v-container class="pa-0">
    <h1 class="mx-2">
      <v-chip color="primary" pill>{{ task.lfdnr }}</v-chip>
      {{ task.title.title }}
    </h1>
    <RichTextEditor
      class="pa-2"
      :text="task.description"
      placeholder="Hier Text eingeben"
      editable
      optionalEditing
      editingCancel
    />
    <v-container class="pa-4 d-flex justify-space-between">
      <span class="text-center">
        <v-btn icon><v-icon>mdi-checkbox-blank-outline</v-icon></v-btn>
        <div class="text-subtitle-2">Status</div>
      </span>
      <span class="text-center">
        <v-btn icon><v-icon color="primary">mdi-clock</v-icon></v-btn>
        <div class="text-subtitle-2">Datum</div>
      </span>
      <span class="text-center">
        <v-btn icon><v-icon color="primary">mdi-checkbox-marked</v-icon></v-btn>
        <div class="text-subtitle-2">Benachr.</div>
      </span>
    </v-container>
    <v-expansion-panels v-model="panel" accordion multiple flat>
      <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header class="text-subtitle-1">
          <div>
            <v-icon class="mr-2 mb-1" color="primary"
              >mdi-account-supervisor-circle</v-icon
            ><span>Basis</span>
            <v-icon class="mb-1 ml-1" small>mdi-pencil</v-icon>
          </div></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div class="d-flex justify-space-between">
            <v-list class="ml-n4">
              <v-list-item
                v-for="adr in objects.addresses"
                :key="adr.wfcid"
                class="mt-n3"
              >
                <v-list-item-icon class="mr-1"
                  ><v-icon small>mdi-account</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-liste-item-title class="text-subtitle-2">{{
                    adr.displayName
                  }}</v-liste-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list class="ml-n4">
              <v-list-item
                v-for="doc in objects.documents"
                :key="doc.wfcid"
                class="mt-n3"
              >
                <v-list-item-icon class="mr-1"
                  ><v-icon small>mdi-file-document</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-liste-item-title class="text-subtitle-2">{{
                    doc.displayName
                  }}</v-liste-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-divider></v-divider>
      <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header class="text-subtitle-1"
          ><div>
            <v-icon class="mr-2 mb-1" color="primary">mdi-account-tie</v-icon
            ><span>Zuordnung</span>
            <v-icon class="mb-1 ml-1" small>mdi-pencil</v-icon>
          </div></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <div class="d-flex justify-space-between">
            <v-list class="ml-n4">
              <v-list-item
                v-for="usr in task.assignedUsers"
                :key="usr.userId"
                class="mt-n3"
              >
                <v-list-item-icon class="mr-1"
                  ><v-icon small>mdi-account</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-liste-item-title class="text-subtitle-2">{{
                    usr.username
                  }}</v-liste-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list class="ml-n4">
              <v-list-item
                v-for="grp in task.assignedGroups"
                :key="grp.code"
                class="mt-n3"
              >
                <v-list-item-icon class="mr-1"
                  ><v-icon small>mdi-account-group</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-liste-item-title class="text-subtitle-2">{{
                    grp.name
                  }}</v-liste-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="mb-10">
        <v-divider></v-divider>
        <v-expansion-panel-header class="text-subtitle-1"
          ><div>
            <v-icon class="mr-2 mb-1" color="primary">mdi-comment</v-icon
            ><span>Kommentare</span>
          </div></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-list>
            <v-list-item v-for="com in task.comments" :key="com.lfdnr">
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2">
                  {{ com.userId }} am
                  {{ dateTimeService.getShortGermanDate(com.creationDate) }}
                </v-list-item-title>
                <RichTextEditor
                  :text="com.commentText"
                  :saveId="com.id"
                  placeholder="Hier Text eingeben"
                  editable
                  optionalEditing
                  editingCancel
                  @save="commentEdited"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="text-subtitle-2"
                  >Neuer Kommentar</v-list-item-title
                >
                <RichTextEditor
                  ref="richTextNewCommentEditor"
                  :text="newComment"
                  placeholder="Hier Text eingeben"
                  editable
                />
                <v-btn
                  elevation="0"
                  color="primary"
                  class="mt-2"
                  @click="addComment"
                  ><v-icon>mdi-plus</v-icon>Hinzufügen</v-btn
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-bottom-navigation
      v-model="value"
      fixed
      permanent
      class="elevation-0 mt-10"
    >
      <v-btn value="history">
        <span>Verlauf</span>

        <v-icon>mdi-history</v-icon>
      </v-btn>

      <v-btn value="delete">
        <span>Löschen</span>

        <v-icon color="error">mdi-delete</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import dateTimeService from '@/services/cal/dateTimeService.js'
import RichTextEditor from '@/components/core/RichTextEditor.vue'

export default {
  components: {
    RichTextEditor
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    objects: {
      addresses: [],
      documents: []
    },
    noToolbar: {
      modules: {
        toolbar: false
      }
    },
    panel: '',
    dateTimeService
  }),
  computed: {
    ...mapState({
      task: state => state.task.taskDetailed,
      user: state => state.user.user
    })
  },
  mounted() {
    this.loadTask()
    this.initializeToolbars()
  },
  methods: {
    loadTask() {
      this.$store.dispatch('loadTask', this.lfdnr).then(() => {
        this.getObjects()
      })
    },
    getObjects() {
      this.task.objects.forEach(element => {
        console.warn(element)
        if (element.type === 'ADDRESS') {
          this.objects.addresses.push(element)
        } else if (element.type === 'DOCUMENT') {
          this.objects.documents.push(element)
        }
      })
    },
    initializeToolbars() {
      const quill = this.$refs.description.quill
      const toolbar = this.$refs.description.quill.getModule('toolbar')
      toolbar.container.style.display = 'none'
      quill.container.style.border = 'solid 1px #cccccc'
    }
  }
}
</script>

<style></style>
