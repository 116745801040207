<template>
  <v-layout class="mt-12">
    <v-row>
      <v-col cols="9">
        <v-container class="px-2 py-0">
          <v-container>
            <h1 class="d-flex justify-start align-center">
              <v-chip color="primary mr-2"># {{ task.lfdnr }}</v-chip>
              {{ task.title.title }}
            </h1>
            <v-divider class="my-3"></v-divider>
            <v-container>
              <RichTextEditor
                ref="richTextDescriptionEditor"
                :text="task.description"
                placeholder="Hier Text eingeben"
                editable
                optionalEditing
                editingCancel
              />
            </v-container>
          </v-container>
          <v-container class="mt-5">
            <h2 class="mt-3">Kommentare</h2>
            <v-divider class="my-3"></v-divider>
            <v-list>
              <v-list-item v-for="com in task.comments" :key="com.lfdnr">
                <v-list-item-icon
                  ><v-icon>mdi-comment</v-icon></v-list-item-icon
                >
                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center"
                    >{{ com.userId }} am
                    {{ dateTimeService.getGermanDateFormat(com.creationDate)
                    }}<v-btn icon small class="ml-3"
                      ><v-icon
                        small
                        color="error"
                        v-if="com.userId === user.userId"
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </v-list-item-title>
                  <RichTextEditor
                    :text="com.commentText"
                    :saveId="com.id"
                    placeholder="Hier Text eingeben"
                    editable
                    optionalEditing
                    editingCancel
                    @save="commentEdited"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon
                  ><v-icon color="primary"
                    >mdi-comment-edit-outline</v-icon
                  ></v-list-item-icon
                >
                <v-list-item-content>
                  <RichTextEditor
                    ref="richTextNewCommentEditor"
                    :text="newComment"
                    placeholder="Hier Text eingeben"
                    editable
                  />
                  <v-row class="mt-1">
                    <v-spacer></v-spacer>
                    <v-col cols="3">
                      <v-btn elevation="0" color="primary" @click="addComment"
                        ><v-icon>mdi-plus</v-icon>Kommentar verfassen</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-container>
        </v-container>
        <WarningDialog
          ref="warningDelete"
          @warningActionPerformed="deleteTask"
        />
        <TaskHistory ref="history" />
        <TaskObjects ref="objects" />
        <TaskAssigned ref="assigned" />
        <DatePicker ref="datePicker" @datePicked="datePicked" />
      </v-col>
      <v-col cols="3">
        <v-navigation-drawer
          class="overflow-y-auto pa-5 pt-15"
          style="height: 100%"
          permanent
          fixed
          clipped
          right
          width="fit-content"
        >
          <template v-slot:prepend>
            <v-list-item class="mt-8">
              <v-list-item-icon>
                <v-btn
                  icon
                  @click="openDatePicker"
                  id="no-background-hover"
                  :ripple="false"
                >
                  <v-icon color="primary">mdi-clock</v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Fälligkeitsdatum</v-list-item-title>
                <v-list-item-subtitle
                  ><v-icon
                    v-if="dateTimeService.isBeforeToday(task.dueDate)"
                    small
                    class="mb-1 mr-1"
                    color="warning"
                    >mdi-alert</v-icon
                  >{{
                    dateTimeService.getShortGermanDate(task.dueDate)
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon
                ><v-btn
                  icon
                  v-if="!task.status.done"
                  id="no-background-hover"
                  :ripple="false"
                  @click="setStatus(true)"
                >
                  <v-icon>
                    mdi-checkbox-blank-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-if="task.status.done"
                  id="no-background-hover"
                  :ripple="false"
                  @click="setStatus(false)"
                >
                  <v-icon color="primary">
                    mdi-checkbox-marked
                  </v-icon>
                </v-btn></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>Status </v-list-item-title
                ><v-list-item-subtitle>{{
                  task.status.description
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-btn
                  icon
                  v-if="!task.notificationsActive"
                  id="no-background-hover"
                  :ripple="false"
                  @click="setNotifications(true)"
                >
                  <v-icon>
                    mdi-checkbox-blank-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-else-if="task.notificationsActive"
                  id="no-background-hover"
                  :ripple="false"
                  @click="setNotifications(false)"
                >
                  <v-icon color="primary">
                    mdi-checkbox-marked
                  </v-icon>
                </v-btn></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>Benachrichtigungen </v-list-item-title
                ><v-list-item-subtitle>{{
                  notificationLabel
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider class="my-3"></v-divider>
          <h3 class="mx-2 text-h6">
            Basis
            <v-btn icon small @click="openTaskObjects"
              ><v-icon small>mdi-pencil</v-icon></v-btn
            >
          </h3>
          <v-list>
            <v-list-item
              v-for="adr in objects.addresses"
              :key="adr.wfcid"
              class="mt-n3"
            >
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon
              ><v-list-item-content>
                <v-list-item-title>{{ adr.displayName }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  adr.wfCid
                }}</v-list-item-subtitle></v-list-item-content
              ></v-list-item
            ></v-list
          >
          <v-list>
            <v-list-item
              v-for="doc in objects.documents"
              :key="doc.wfcid"
              class="mt-n3"
            >
              <v-list-item-icon
                ><v-icon>mdi-file-document</v-icon></v-list-item-icon
              ><v-list-item-content>
                <v-list-item-title>{{ doc.displayName }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  doc.wfCid
                }}</v-list-item-subtitle></v-list-item-content
              ></v-list-item
            >
          </v-list>
          <v-divider class="my-3"></v-divider>
          <h3 class="mx-2 text-h6">
            Zuordnung
            <v-btn icon small @click="openTaskAssigned"
              ><v-icon small>mdi-pencil</v-icon></v-btn
            >
          </h3>
          <v-list>
            <v-list-item
              v-for="usr in task.assignedUsers"
              :key="usr.userId"
              class="mt-n3"
            >
              <v-list-item-icon
                ><v-icon>mdi-account-tie</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>{{ usr.username }}</v-list-item-title>
                <v-list-item-subtitle>{{ usr.userId }}</v-list-item-subtitle>
              </v-list-item-content></v-list-item
            >
          </v-list>

          <v-list-group
            prepend-icon="mdi-account-group"
            v-for="grp in task.assignedGroups"
            :key="grp.code"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ grp.name }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="usr in grp.users"
              :key="usr.userId"
              class="ml-2 mt-n3"
              ><v-list-item-icon
                ><v-icon>mdi-account-tie</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title>{{ usr.username }}</v-list-item-title>
                <v-list-item-subtitle>{{ usr.userId }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <template v-slot:append>
            <div class="d-flex justify-center">
              <v-btn
                @click="openTaskHistory"
                color="primary"
                elevation="0"
                class="mr-2"
                :disabled="task.history.length === 0"
              >
                <span>Verlauf</span>

                <v-icon>mdi-history</v-icon>
              </v-btn>

              <v-btn @click="tryDeleteTask" color="error" elevation="0">
                <span>Löschen</span>

                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-navigation-drawer>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import dateTimeService from '@/services/cal/dateTimeService.js'
import RichTextEditor from '@/components/core/RichTextEditor.vue'
import WarningDialog from '@/components/core/WarningDialog.vue'
import TaskHistory from '@/components/task/TaskHistory.vue'
import TaskObjects from '@/components/task/TaskObjects.vue'
import TaskAssigned from '@/components/task/TaskAssigned.vue'
import DatePicker from '@/components/task/DatePicker.vue'

export default {
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  components: {
    RichTextEditor,
    WarningDialog,
    TaskHistory,
    TaskObjects,
    TaskAssigned,
    DatePicker
  },
  data: () => ({
    dateTimeService,
    objects: {
      addresses: [],
      documents: []
    },
    descriptionEditable: false,
    noToolbar: {
      modules: {
        toolbar: false
      }
    },
    placeholder: {
      placeholder: 'Neuer Kommentar'
    },
    newComment: ''
  }),
  computed: {
    ...mapState({
      task: state => state.task.taskDetailed,
      user: state => state.user.user
    }),
    notificationLabel: function() {
      if (this.task.notificationsActive) return 'aktiviert'
      else return 'deaktiviert'
    },
    currentDate: function() {
      return new Date()
    }
  },
  methods: {
    loadTask() {
      this.$store.dispatch('loadTask', this.lfdnr).then(() => {
        this.getObjects()
      })
    },
    getObjects() {
      this.task.objects.forEach(element => {
        console.warn(element)
        if (element.type === 'ADDRESS') {
          this.objects.addresses.push(element)
        } else if (element.type === 'DOCUMENT') {
          this.objects.documents.push(element)
        }
      })
    },
    setStatus(value) {
      this.task.status.done = value
    },
    setNotifications(value) {
      this.task.notificationsActive = value
    },
    initializeToolbars() {
      const quill = this.$refs.quillDescriptionEdit.quill
      const toolbar = this.$refs.quillDescriptionEdit.quill.getModule('toolbar')
      toolbar.container.style.display = 'none'
      quill.container.style.border = 'solid 1px #cccccc'
    },
    changeDescriptionEditable() {
      this.descriptionEditable = !this.descriptionEditable
      const toolbar = this.$refs.quillDescriptionEdit.quill.getModule('toolbar')
      toolbar.container.style.display = this.descriptionEditable
        ? 'block'
        : 'none'
    },
    addComment() {
      var commentTemp = {}
      commentTemp.userId = this.user.userId
      commentTemp.commentText = this.$refs.richTextNewCommentEditor.editor.getHTML()
      commentTemp.creationDate = new Date()
      this.task.comments.push(commentTemp)
      this.newComment = ''
      //TODO: AXIOS CALL
    },
    openTaskHistory() {
      this.$refs.history.open(this.task.history)
    },
    openTaskObjects() {
      this.$refs.objects.open(this.objects)
    },
    openTaskAssigned() {
      this.$refs.assigned.open(
        this.task.assignedUsers,
        this.task.assignedGroups
      )
    },
    openDatePicker() {
      this.$refs.datePicker.open(
        dateTimeService.getDateStringFromDateTimeString(this.task.dueDate)
      )
    },
    datePicked(date) {
      this.task.dueDate = date
    },
    tryDeleteTask() {
      var actions = [
        {
          icon: 'mdi-delete',
          text: 'Löschen',
          action: 'delete'
        },
        { icon: 'mdi-close', text: 'Abbrechen', action: 'cancel' }
      ]

      this.$refs.warningDelete.open('Wollen Sie die Aufgabe löschen?', actions)
    },
    deleteTask() {
      alert('Task wird gelöscht')
      //TODO: AXIOS CALL
    }
  },
  mounted() {
    this.loadTask()
    setTimeout(() => {
      this.initializeToolbars()
    }, 500)
  }
}
</script>

<style scoped>
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
