<template>
  <div>
    <div v-if="isMobile()">
      <TaskDetailMobile :lfdnr="lfdnr"></TaskDetailMobile>
    </div>
    <div v-else><TaskDetail :lfdnr="lfdnr"></TaskDetail></div>
  </div>
</template>

<script>
import TaskDetail from './TaskDetail.vue'
import TaskDetailMobile from './TaskDetailMobile.vue'

export default {
  components: {
    TaskDetail,
    TaskDetailMobile
  },
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  computed: {
    width: function() {
      return window.innerWidth
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style></style>
