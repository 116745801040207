<template>
  <v-dialog v-model="taskHistory" scrollable max-width="1000px" persistent>
    <v-card>
      <v-card-title class="mx-0 px-0 my-0 py-0">
        <v-toolbar color="primary" class="mx-0 px-0 my-0 py-0" fixed top dark>
          <v-icon @click="taskHistory = false">mdi-close</v-icon>
          <v-toolbar-title class="flex text-center"
            >Aufgabenverlauf</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-timeline>
          <v-timeline-item v-for="entry in history" :key="entry.lfdnr"
            ><v-card>
              <v-card-title>{{ entry.title }} </v-card-title>
              <v-card-subtitle
                >{{ entry.userId }} am
                {{
                  dateTimeService.getGermanDateFormat(entry.date)
                }}</v-card-subtitle
              >
              <v-card-text>{{ entry.description }}</v-card-text>
            </v-card></v-timeline-item
          >
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="taskHistory = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dateTimeService from '@/services/cal/dateTimeService.js'

export default {
  data: () => ({
    taskHistory: false,
    dateTimeService,
    history: []
  }),
  components: {},
  methods: {
    open(history) {
      this.history = history
      this.taskHistory = true
    }
  }
}
</script>

<style></style>
